<template>
  <static-fullscreen-card>
    <template v-slot:title> ({{ data.id }}) {{ data.name }} [{{ statusCurrent.text }}] </template>
    <template v-slot:actions>
      <a-btn-add v-if="getAccess('leads.actions.create')" icon="far fa-clock" title="Задача" @click="onCreateTask()" :disabled="loading" />
      <a-btn-add v-if="getAccess('leads.actions.create')" icon="fas fa-tty" title="Действие" @click="onCreateAction()" :disabled="loading" />

      <a-btn-status v-if="getAccess('leads.status')" :items="statusItems" @click="clickStatus($event)" :disabled="loading" />
      <a-btn-edit v-if="editLead" title="Редактирование" @click1="initEditDialog++" @click="onEdit()" />
    </template>
    <portal to="v-goup" v-if="idShow">
      <v-btn class="mr-3" fab small @click="$emit('close', data)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </portal>
    <portal to="v-main">
      <s-edit-form
        v-model="showEditDialog"
        v-if="showEditDialog"
        :m="configH.m"
        :configForm="{ head: configH.head, title: configH.title, api: configH.api }"
        :id="configH.id"
        :opt="configH.opt"
        :initData="configH.initData || {}"
        :params="configH"
      />
    </portal>
    <v-row v-if="!loading">
      <v-col cols="12" sm="6">
        <material-card color="secondary">
          <template v-slot:heading>
            <div class="px-3 py-1">Клиент</div>
          </template>
          <a-form-view :value="data" :model="modelLeft" :config="{ dense: true }" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6">
        <material-card color="secondary">
          <template v-slot:heading>
            <div class="px-3 py-1">Контакты</div>
          </template>
          <a-form-view :value="data" :model="modelRight" :config="{ dense: true }" />
        </material-card>
      </v-col>
      <v-col cols="12" v-if="!loading">
        <material-card color="third" class="block_detail">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color1="white" show-arrows center-active @change="changeTab(tab)">
              <v-tab v-for="(tab, i) in tabs" :key="i" v-if="showTab(tab.name)" class="mr-3">{{ tab.title }} </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px">
            <v-tab-item v-for="(tab, i) in tabs" :key="i" v-if="showTab(tab.name)" :data-name="`${tab.name} section`">
              <comment-view v-if="tab.name == 'comments'" :id="id || 0" :targetName="'rent'" :needUpdate="needUpdateComments" :height="detailHeight" />
              <TabActions v-if="tab.name == 'actions'" ref="tabActions" :id="id" :height="detailHeight" />
              <TabTasks v-if="tab.name == 'tasks'" ref="tabTasks" :id="id" :height="detailHeight" @action="onTaskAction($event)" @edit="onEditTask($event)" />
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
  </static-fullscreen-card>
</template>

<script>
import { statusChange, getAccess, autoHeightBlock, genModel, statusDoc } from "@/components/mixings";
export default {
  mixins: [getAccess, statusChange, autoHeightBlock, genModel, statusDoc],
  components: {
    TabActions: () => import("./tabs/tabLeadActions"),
    TabTasks: () => import("./tabs/tabLeadTasks"),
  },
  props: {
    idShow: Number,
  },
  data() {
    return {
      id: 0,
      needUpdateComments: 0,
      loading: true,
      addDocModel: null,
      showEditDialog: false,
      configH: null,
      confirmDialog: false,
      title: "",
      data: {},
      m: this.$store.getters["config/get"].models.leads,
      tab: 0,
      initEditDialog: 0,
      clientHeight: 0,
    };
  },
  created() {
    this.loading = true;
    this.$root.title = "Лид";
    this.id = this.idShow || Number(this.$route.params.id);
    this.fitchData();
    if (this.$attrs?.tab) {
      this.tab = parseInt(this.$attrs.tab);
    }
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        if (this.configH.type == "lead") this.fitchData();
        if (this.configH.type == "tasks") {
          if (this.$refs.tabTasks) {
            this.$refs.tabTasks[0].$refs.table.updateData();
          }
        }
        if (this.configH.type == "actions") {
          if (this.$refs.tabActions) {
            this.$refs.tabActions[0].$refs.table.updateData();
          }
        }
        this.configH = null;
      }
    },
  },
  computed: {
    url() {
      return this.m.api;
    },

    model: {
      get() {
        let model = this.getModelList(this.m, "form", true);
        console.log("model", model);
        return model;
      },
    },
    modelLeft() {
      return this.calcModel("viewForm1");
    },
    modelRight() {
      return this.calcModel("viewForm2");
    },
    tabs() {
      let tabs = [
        { name: "tasks", title: "Задачи", show: this.getAccess("leads.actions.view") },
        { name: "actions", title: "Действия", show: this.getAccess("leads.actions.view") },
      ];
      return tabs;
    },

    editLead() {
      return this.getAccess("leads.edit");
    },
    configHead() {
      return this.m.config.manager || {};
    },
  },
  methods: {
    onTaskAction({ task, type }) {
      if (type == "done") {
        const config = {};
        config.type = "actions";
        this.configH = null;
        config.head = this.m.actions.config.done || {};
        config.title = this.m.actions.config.done.title;
        config.api = this.m.actions.api;
        config.id = null;
        config.opt = this.getAccess("leads.actions.create") && task.status == 1 ? {} : { readonly: true };
        config.initData = { lead_id: this.id, task_id: task.id, type: task.type };
        config.m = this.m.actions;
        this.configH = config;
        this.showEditDialog = true;
      }
    },
    onEdit() {
      const config = {};
      config.type = "lead";
      this.configH = null;
      config.head = this.m.config.manager || {};
      config.title = this.m.config.manager.title || {};
      config.api = this.url;
      config.id = this.id;
      config.opt = { delete: true };
      config.m = this.m;
      this.configH = config;
      this.showEditDialog = true;
    },
    onCreateTask(e) {
      this.onEditTask();
    },
    onEditTask(id = null) {
      const config = {};
      config.type = "tasks";
      this.configH = null;
      config.head = this.m.tasks.config.default || {};
      config.title = this.m.tasks.config.default.title || {};
      config.api = this.m.tasks.api;
      config.id = id;
      config.opt = this.getAccess("leads.actions.create") ? {} : { readonly: true };
      config.func = { readonly: "(d)=>{return d.status!==1}" };
      config.initData = { lead_id: this.id };
      config.m = this.m.tasks;
      this.configH = config;
      this.showEditDialog = true;
    },
    onCreateAction() {
      const config = {};
      config.type = "actions";
      this.configH = null;
      config.head = this.m.actions.config.default || {};
      config.api = this.m.actions.api;
      config.id = null;
      config.opt = this.getAccess("leads.actions.create") ? {} : { readonly: true };
      config.initData = { lead_id: this.id };
      config.m = this.m.actions;
      this.configH = config;

      this.showEditDialog = true;
    },
    showTab(n) {
      if (n == "acc") return false;
      return true;
    },
    showDocs(field) {
      return this.model.find((el) => el.name == field)?.category || -1;
    },
    changeTab(tab) {
      if (!this.idShow) this.$router.push({ query: { tab } });
    },
    async fitchData() {
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.loading = false;
    },

    updateData() {
      this.fitchData();
    },
  },
};
</script>

<style lang="sass">
.v-slide-group1__prev--disabled
  display: none !important
.v-slide-group1__next--disabled
  display: none !important
</style>
